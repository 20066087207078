.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: black;
}
h1 {
  color: white;
}

.loaderCenterVisible {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-image {
  width: auto;
  height: auto;
}
