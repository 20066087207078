body::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: osake-font;
  src: url("../assets/osake-x3oqj font.otf");
}
.osake-font-apply{
  font-family: osake-font;
}

.header-component {
  display: flex;
  margin-top: 2%;
  margin-left: 3%;
  flex-direction: row;
  width: 130%;
  justify-content: space-between;
}

.element {
  padding-right: 38%;
}

body {
  overflow: hidden;
}
#parallax {
  position: relative;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  width: 102%;
  height: 102vh;
  background-image: url("../assets/new-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin-top: -1vh;
  margin-left: -1%;
}
.body {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/new-bg.png");
}

.list-items{
  display: flex;
  flex-direction: row;
  /* margin-left: 35%; */
}

.mute {
  color: white;
  position: absolute;
  top: 40px;
  right: 50px;
  cursor: pointer;
}
.container {
  width: 100%;
  height: 56vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  font-size: 32px;
  text-align: center;
  color: #caeaff;
  font-weight: lighter !important;
  text-shadow: 0 0 20px hsl(0deg 0% 100% / 40%), 0 0 5px hsl(0deg 0% 100% / 30%),
    0 0 1px #00d0ff;
  letter-spacing: 2px;
  text-transform: uppercase;
}

p span span {
  position: relative;
  animation: move-text-color 0.75s forwards;
  bottom: -1em;
  opacity: 0;
}

.bar {
  font-weight: lighter !important;
  text-shadow: 0 0 20px hsl(0deg 0% 100% / 40%), 0 0 5px hsl(0deg 0% 100% / 30%),
    0 0 1px #00d0ff;
  letter-spacing: 2px;
  color: #caeaff;
  animation: display 1s forwards infinite;
  animation-delay: 7s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.textContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 32%;
}

.long-text{
  background-image: url("../assets/Vector.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.button {
  outline: none;
  border: none;
  text-align: center;
  color: #caeaff;
  background-color: inherit;
  font-size: 16px;
  font-weight: lighter;
  margin-top: 15px;
  border: 1px solid#caeaff;
  padding: 10px 30px;
  border-radius: 15px;
  transition: transform 0.5s;
  cursor: pointer;
}
.button:hover {
  transform: translateY(5px);
}

.connect-wallet-button {
  background-color: rgb(232, 4, 5);
  color: rgb(0, 0, 0);
  font-family: Oswald, sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 10px;
  width: 300px;
  height: 70px;
  cursor: pointer;
  margin-left: 15%;
  margin-top: 8%;
}

.number-of-mint{
  top: 40px;
  width: 260px;
  height: auto;;
  background: rgba(8, 8, 8, 0.3);
  color: #FFFD41;
  font-size: 28px;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 6%;
  margin-bottom: -10%;
  margin-left: 15%;
  padding-left: 2%;
  padding-right: 2%;
  text-align: center;
}

.wallet-address{
  width: 180px;
  padding-left: 3%;
  height: 47px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
  color: #FFFD41;
  font-size: 28px;
  font-weight: 700;
  margin-left: 20%;
}

.message {
  width: 716px;
  height: 47px;
  text-align: left;
  font: normal normal 600 32px/47px Oswald;
  letter-spacing: 0px;
  background: rgba(7, 7, 7, 0.3);
  color: #FFFD41;
  text-transform: uppercase;
  margin-top: 10%;
  margin-bottom: -15%;
  margin-left: -5%;
  padding-left: 2%;
  border-radius: 8px;
}

.message-loader{
  width: 180px;
  height: 47px;
  text-align: left;
  font: normal normal 600 32px/47px Oswald;
  letter-spacing: 0px;
  background: rgba(0, 0, 0, 0.3);
  color: #FFFD41;
  text-transform: uppercase;
  margin-top: 3%;
  margin-left: 16%;
  padding-left: 6%;
  border-radius: 8px;
}

/* //////////////   MEDUA QURIES ///////////////////////// */

/* //////////  ANIMATIONS   ////////////////// */

@keyframes display {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move-text-color {
  0% {
    bottom: -0.2em;
    opacity: 1;
    color: #3e5c76;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}
@media (max-width: 768px) {
  #parallax {
    width: 100%;
    height: 100vh;
    margin-top: 0vh;
    margin-left: 0%;
    /* transform: translate(0); */
  }
}
