/* parallx css  */
/* #root {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  background-image: url("./assets/image.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
} */

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1382px 828px #fff, 1647px 565px #fff, 1579px 1995px #fff,
    1358px 829px #fff, 1994px 213px #fff, 1157px 607px #fff, 637px 1908px #fff,
    119px 1802px #fff, 645px 1554px #fff, 1px 1634px #fff, 1777px 640px #fff,
    512px 916px #fff, 975px 676px #fff, 231px 1671px #fff, 76px 1975px #fff,
    655px 1505px #fff, 640px 1472px #fff, 312px 363px #fff, 1504px 238px #fff,
    745px 1198px #fff, 679px 1866px #fff, 788px 1511px #fff, 1752px 1056px #fff,
    532px 459px #fff, 1344px 135px #fff, 1351px 1258px #fff, 1216px 1013px #fff,
    1405px 624px #fff, 1457px 1230px #fff, 1558px 1627px #fff, 496px 189px #fff,
    1573px 618px #fff, 810px 1565px #fff, 1298px 448px #fff, 12px 959px #fff,
    969px 1529px #fff, 51px 409px #fff, 1289px 389px #fff, 1363px 1417px #fff,
    1836px 683px #fff, 1628px 1141px #fff, 736px 31px #fff, 370px 1997px #fff,
    1971px 483px #fff, 1474px 849px #fff, 1746px 1274px #fff, 1699px 22px #fff,
    1660px 1581px #fff, 1565px 738px #fff, 1666px 500px #fff, 38px 1535px #fff,
    899px 509px #fff, 1063px 1306px #fff, 1775px 1553px #fff, 1994px 999px #fff,
    1419px 1693px #fff, 196px 856px #fff, 1216px 1602px #fff, 308px 929px #fff,
    50px 1437px #fff, 560px 888px #fff, 1590px 368px #fff, 1212px 349px #fff,
    1984px 1751px #fff, 1106px 1682px #fff, 811px 456px #fff, 1796px 1320px #fff,
    405px 1062px #fff, 1299px 1195px #fff, 731px 880px #fff, 52px 686px #fff,
    698px 189px #fff, 880px 935px #fff, 1624px 912px #fff, 1008px 1132px #fff,
    703px 92px #fff, 1542px 119px #fff, 1380px 1993px #fff, 832px 641px #fff,
    970px 406px #fff, 940px 1353px #fff, 21px 502px #fff, 1832px 642px #fff,
    1929px 1098px #fff, 1510px 1280px #fff, 1644px 1257px #fff,
    1539px 1299px #fff, 1710px 1630px #fff, 783px 236px #fff, 468px 1849px #fff,
    1744px 1356px #fff, 1379px 1500px #fff, 825px 43px #fff, 695px 1298px #fff,
    1612px 1745px #fff, 579px 1799px #fff, 1187px 717px #fff, 13px 1689px #fff,
    115px 1658px #fff, 1448px 832px #fff, 562px 1056px #fff, 1597px 1538px #fff,
    455px 1124px #fff, 1063px 385px #fff, 1879px 219px #fff, 1471px 1478px #fff,
    795px 1573px #fff, 110px 1694px #fff, 827px 498px #fff, 1347px 736px #fff,
    890px 938px #fff, 519px 846px #fff, 1365px 1337px #fff, 1880px 1669px #fff,
    361px 1148px #fff, 1987px 1191px #fff, 1958px 1106px #fff, 1999px 113px #fff,
    28px 1537px #fff, 669px 759px #fff, 1468px 446px #fff, 639px 307px #fff,
    1016px 1499px #fff, 1678px 1263px #fff, 796px 1516px #fff, 1703px 24px #fff,
    897px 630px #fff, 1587px 1592px #fff, 1702px 1955px #fff, 1025px 1281px #fff,
    647px 1092px #fff, 1670px 1167px #fff, 265px 1917px #fff, 1355px 1980px #fff,
    29px 397px #fff, 311px 946px #fff, 521px 196px #fff, 665px 367px #fff,
    148px 133px #fff, 1375px 1253px #fff, 723px 986px #fff, 1928px 962px #fff,
    1620px 357px #fff, 674px 261px #fff, 1172px 530px #fff, 1728px 1995px #fff,
    832px 398px #fff, 1037px 1923px #fff, 1258px 1178px #fff, 1913px 1507px #fff,
    470px 315px #fff, 506px 1138px #fff, 335px 220px #fff, 1530px 1599px #fff,
    1254px 977px #fff, 1428px 1254px #fff, 508px 623px #fff, 1147px 1958px #fff,
    716px 950px #fff, 1151px 1578px #fff, 255px 956px #fff, 627px 1570px #fff,
    1372px 259px #fff, 556px 656px #fff, 925px 1407px #fff, 222px 386px #fff,
    512px 431px #fff, 1670px 1929px #fff, 495px 742px #fff, 845px 586px #fff,
    1990px 1760px #fff, 345px 1199px #fff, 1902px 817px #fff, 532px 680px #fff,
    643px 504px #fff, 436px 1284px #fff, 167px 1984px #fff, 480px 1085px #fff,
    925px 1507px #fff, 610px 1966px #fff, 1615px 1097px #fff, 834px 1886px #fff,
    1355px 1393px #fff, 1538px 1226px #fff, 1287px 1336px #fff, 471px 545px #fff,
    68px 1950px #fff, 1927px 1988px #fff, 1226px 1006px #fff, 1540px 148px #fff,
    1215px 594px #fff, 212px 1598px #fff, 1627px 532px #fff, 132px 660px #fff,
    1795px 1063px #fff, 519px 554px #fff, 1798px 799px #fff, 1483px 118px #fff,
    885px 1016px #fff, 1864px 2px #fff, 1382px 842px #fff, 1578px 1033px #fff,
    1669px 1859px #fff, 1889px 1125px #fff, 1555px 417px #fff, 21px 1255px #fff,
    715px 1277px #fff, 882px 89px #fff, 699px 280px #fff, 1078px 905px #fff,
    1137px 385px #fff, 823px 250px #fff, 1536px 1215px #fff, 1479px 1941px #fff,
    402px 1615px #fff, 1155px 1632px #fff, 923px 1694px #fff, 549px 1196px #fff,
    438px 782px #fff, 271px 1912px #fff, 713px 1608px #fff, 987px 265px #fff,
    1946px 374px #fff, 1688px 804px #fff, 745px 898px #fff, 944px 1596px #fff,
    1883px 413px #fff, 1418px 864px #fff, 1427px 161px #fff, 1714px 1065px #fff,
    1791px 1877px #fff, 813px 1557px #fff, 1877px 1659px #fff, 575px 1242px #fff,
    1902px 1273px #fff, 62px 1307px #fff, 907px 493px #fff, 612px 1005px #fff,
    1584px 1571px #fff, 1541px 246px #fff, 817px 1979px #fff, 1879px 1160px #fff,
    1377px 1559px #fff, 872px 1060px #fff, 269px 1179px #fff, 241px 1172px #fff,
    932px 1736px #fff, 1749px 1237px #fff, 1500px 1389px #fff, 1543px 899px #fff,
    931px 1278px #fff, 1027px 1740px #fff, 1806px 1788px #fff, 1136px 27px #fff,
    1481px 650px #fff, 1133px 491px #fff, 904px 479px #fff, 150px 989px #fff,
    255px 747px #fff, 1815px 1956px #fff, 1016px 419px #fff, 1341px 1060px #fff,
    455px 1264px #fff, 1206px 830px #fff, 675px 1973px #fff, 990px 598px #fff,
    252px 460px #fff, 1723px 1812px #fff, 729px 1546px #fff, 575px 1649px #fff,
    818px 250px #fff, 1432px 54px #fff, 1441px 59px #fff, 1875px 1109px #fff,
    650px 754px #fff, 1562px 1139px #fff, 407px 1633px #fff, 1317px 1442px #fff,
    1430px 1320px #fff, 1980px 1482px #fff, 2000px 1618px #fff,
    383px 1739px #fff, 538px 448px #fff, 1508px 1142px #fff, 1781px 454px #fff,
    1776px 1461px #fff, 653px 1040px #fff, 1397px 630px #fff, 213px 1229px #fff,
    1150px 1389px #fff, 1937px 265px #fff, 887px 396px #fff, 978px 910px #fff,
    871px 1469px #fff, 318px 510px #fff, 409px 677px #fff, 1915px 1283px #fff,
    1432px 1022px #fff, 1437px 1823px #fff, 1562px 550px #fff,
    1127px 1056px #fff, 1496px 76px #fff, 1758px 73px #fff, 1916px 1189px #fff,
    679px 1809px #fff, 1224px 585px #fff, 22px 49px #fff, 277px 1568px #fff,
    396px 571px #fff, 830px 308px #fff, 1093px 1408px #fff, 59px 236px #fff,
    1707px 1010px #fff, 1703px 1835px #fff, 1442px 1636px #fff, 705px 247px #fff,
    97px 1239px #fff, 1235px 343px #fff, 139px 1291px #fff, 829px 80px #fff,
    1497px 258px #fff, 972px 1285px #fff, 1195px 1326px #fff, 1382px 319px #fff,
    1871px 766px #fff, 455px 575px #fff, 978px 1731px #fff, 1524px 1483px #fff,
    177px 1858px #fff, 493px 140px #fff, 718px 1482px #fff, 35px 436px #fff,
    666px 1919px #fff, 595px 796px #fff, 1231px 348px #fff, 1133px 1766px #fff,
    591px 1048px #fff, 1953px 1511px #fff, 645px 1364px #fff, 1289px 311px #fff,
    1408px 277px #fff, 1365px 1403px #fff, 11px 1754px #fff, 1871px 142px #fff,
    983px 809px #fff, 1528px 523px #fff, 1826px 1788px #fff, 742px 834px #fff,
    922px 150px #fff, 297px 507px #fff, 1091px 1862px #fff, 690px 1515px #fff,
    745px 748px #fff, 341px 895px #fff, 1793px 324px #fff, 1571px 400px #fff,
    1386px 243px #fff, 42px 1073px #fff, 538px 1901px #fff, 1317px 1625px #fff,
    94px 1360px #fff, 308px 526px #fff, 737px 532px #fff, 307px 979px #fff,
    965px 1765px #fff, 877px 1043px #fff, 1683px 114px #fff, 109px 573px #fff,
    1421px 600px #fff, 112px 345px #fff, 1278px 931px #fff, 205px 1706px #fff,
    1070px 440px #fff, 1411px 1379px #fff, 62px 623px #fff, 1665px 1555px #fff,
    1956px 578px #fff, 1298px 154px #fff, 1699px 983px #fff, 1453px 828px #fff,
    433px 1684px #fff, 980px 1435px #fff, 469px 189px #fff, 1082px 76px #fff,
    787px 1721px #fff, 255px 1608px #fff, 1598px 1854px #fff, 1196px 1908px #fff,
    350px 1881px #fff, 1387px 1283px #fff, 1415px 264px #fff, 68px 795px #fff,
    1613px 182px #fff, 898px 1221px #fff, 46px 216px #fff, 1633px 727px #fff,
    154px 822px #fff, 1812px 628px #fff, 1830px 1287px #fff, 1184px 457px #fff,
    152px 27px #fff, 327px 1494px #fff, 526px 1264px #fff, 730px 745px #fff,
    1930px 1529px #fff, 932px 1610px #fff, 491px 145px #fff, 163px 1180px #fff,
    130px 795px #fff, 703px 267px #fff, 614px 1417px #fff, 1546px 1569px #fff,
    687px 1062px #fff, 1392px 1259px #fff, 1358px 1138px #fff, 954px 1043px #fff,
    1233px 154px #fff, 553px 1153px #fff, 1315px 759px #fff, 518px 642px #fff,
    389px 1181px #fff, 1962px 1383px #fff, 1008px 1576px #fff,
    1615px 1141px #fff, 364px 1400px #fff, 126px 1530px #fff, 1007px 845px #fff,
    1463px 1868px #fff, 41px 38px #fff, 442px 269px #fff, 1063px 1194px #fff,
    1995px 519px #fff, 511px 290px #fff, 73px 1793px #fff, 1733px 1512px #fff,
    1214px 751px #fff, 1142px 710px #fff, 449px 192px #fff, 902px 1450px #fff,
    158px 389px #fff, 1000px 316px #fff, 1132px 839px #fff, 892px 1570px #fff,
    927px 1931px #fff, 1831px 298px #fff, 531px 632px #fff, 1089px 423px #fff,
    933px 970px #fff, 697px 498px #fff, 1929px 183px #fff, 891px 417px #fff,
    1073px 1206px #fff, 539px 1600px #fff, 603px 439px #fff, 736px 1756px #fff,
    197px 453px #fff, 1781px 23px #fff, 1189px 195px #fff, 1616px 279px #fff,
    535px 769px #fff, 513px 187px #fff, 1497px 711px #fff, 1843px 1611px #fff,
    769px 1342px #fff, 882px 1841px #fff, 1444px 1233px #fff, 309px 952px #fff,
    1526px 1414px #fff, 1355px 1309px #fff, 1480px 463px #fff, 258px 24px #fff,
    49px 33px #fff, 1818px 1842px #fff, 444px 248px #fff, 408px 796px #fff,
    798px 761px #fff, 1481px 1361px #fff, 1718px 41px #fff, 1620px 1759px #fff,
    1327px 1338px #fff, 290px 1669px #fff, 360px 1470px #fff, 167px 1284px #fff,
    512px 880px #fff, 1827px 423px #fff, 1468px 272px #fff, 1453px 327px #fff,
    982px 880px #fff, 1979px 791px #fff, 568px 939px #fff, 1717px 1627px #fff,
    1769px 1615px #fff, 15px 523px #fff, 1152px 1203px #fff, 285px 1666px #fff,
    856px 644px #fff, 25px 1749px #fff, 415px 1955px #fff, 275px 1781px #fff,
    1854px 514px #fff, 974px 317px #fff, 270px 1306px #fff, 705px 585px #fff,
    1776px 1292px #fff, 1797px 1652px #fff, 486px 1113px #fff, 62px 688px #fff,
    1503px 546px #fff, 1915px 1576px #fff, 719px 494px #fff, 1874px 1065px #fff,
    1309px 295px #fff, 1214px 457px #fff, 1845px 1470px #fff, 26px 1635px #fff,
    1864px 635px #fff, 201px 787px #fff, 747px 1754px #fff, 1701px 1636px #fff,
    1783px 1921px #fff, 1583px 1108px #fff, 89px 1361px #fff, 432px 1202px #fff,
    528px 81px #fff, 151px 659px #fff, 911px 318px #fff, 229px 1974px #fff,
    1222px 1339px #fff, 737px 1927px #fff, 1222px 669px #fff, 843px 1796px #fff,
    556px 518px #fff, 953px 896px #fff, 1071px 1567px #fff, 233px 2px #fff,
    1948px 1095px #fff, 626px 1384px #fff, 85px 1684px #fff, 393px 1965px #fff,
    1118px 225px #fff, 305px 1933px #fff, 1868px 1314px #fff, 491px 1576px #fff,
    1470px 417px #fff, 1507px 1214px #fff, 1462px 1629px #fff, 625px 1460px #fff,
    965px 1701px #fff, 904px 154px #fff, 137px 1430px #fff, 875px 1390px #fff,
    755px 2000px #fff, 678px 1830px #fff, 762px 468px #fff, 341px 422px #fff,
    451px 290px #fff, 1868px 1203px #fff, 1641px 1527px #fff, 1993px 1629px #fff,
    900px 252px #fff, 840px 1428px #fff, 96px 1717px #fff, 731px 57px #fff,
    1527px 1433px #fff, 1835px 674px #fff, 1296px 33px #fff, 701px 514px #fff,
    581px 318px #fff, 729px 1292px #fff, 182px 1256px #fff, 913px 360px #fff,
    948px 1018px #fff, 1799px 1791px #fff, 1121px 659px #fff, 1451px 377px #fff,
    187px 173px #fff, 485px 609px #fff, 1133px 661px #fff, 1757px 347px #fff,
    692px 1527px #fff, 1234px 1539px #fff, 1813px 1570px #fff,
    1784px 1279px #fff, 1491px 1565px #fff, 712px 1265px #fff, 594px 1514px #fff,
    795px 976px #fff, 1784px 1953px #fff, 103px 62px #fff, 1134px 765px #fff,
    1083px 794px #fff, 288px 1913px #fff, 1136px 1546px #fff, 73px 1755px #fff,
    972px 130px #fff, 16px 346px #fff, 1756px 1078px #fff, 1058px 1706px #fff,
    1675px 813px #fff, 227px 1710px #fff, 1502px 470px #fff, 163px 302px #fff,
    1406px 923px #fff, 332px 1565px #fff, 1414px 306px #fff, 401px 601px #fff,
    1992px 931px #fff, 1102px 559px #fff, 1605px 1px #fff, 595px 1468px #fff,
    1775px 249px #fff, 1546px 1873px #fff, 1899px 246px #fff, 172px 260px #fff,
    274px 1934px #fff, 645px 1139px #fff, 1213px 1715px #fff, 564px 1669px #fff,
    1795px 1057px #fff, 121px 1768px #fff, 164px 241px #fff, 1146px 1263px #fff,
    71px 1378px #fff, 388px 1518px #fff, 831px 840px #fff, 359px 70px #fff,
    1019px 188px #fff, 153px 1107px #fff, 1720px 1558px #fff, 471px 1354px #fff,
    877px 1473px #fff, 1949px 432px #fff, 882px 764px #fff, 1413px 299px #fff,
    1110px 266px #fff, 1802px 314px #fff, 1426px 325px #fff, 978px 483px #fff,
    438px 387px #fff, 1382px 1913px #fff, 958px 1388px #fff, 326px 737px #fff,
    960px 1660px #fff, 1103px 7px #fff, 894px 1291px #fff, 10px 933px #fff,
    389px 399px #fff, 1026px 1687px #fff, 6px 295px #fff, 1688px 265px #fff,
    1686px 341px #fff, 307px 1885px #fff, 1804px 320px #fff, 578px 1993px #fff,
    512px 1764px #fff, 586px 311px #fff, 1355px 251px #fff, 1306px 1053px #fff,
    583px 530px #fff, 934px 49px #fff, 273px 316px #fff, 747px 1722px #fff,
    616px 179px #fff, 771px 1291px #fff, 817px 521px #fff, 440px 1886px #fff,
    1811px 1908px #fff, 309px 1314px #fff, 1960px 1757px #fff,
    1776px 1301px #fff, 817px 1158px #fff, 1679px 1496px #fff,
    1997px 1331px #fff, 1514px 1048px #fff, 1020px 657px #fff, 1550px 290px #fff,
    228px 1565px #fff, 48px 1791px #fff, 1683px 1960px #fff, 1150px 794px #fff,
    1206px 981px #fff, 1784px 966px #fff, 873px 577px #fff, 1087px 1176px #fff,
    1781px 1806px #fff, 967px 324px #fff, 352px 1187px #fff, 1068px 1591px #fff,
    1967px 1808px #fff, 899px 988px #fff, 1270px 843px #fff, 1881px 1306px #fff,
    668px 581px #fff, 993px 1376px #fff, 1792px 942px #fff, 591px 1057px #fff,
    293px 489px #fff, 1945px 1743px #fff, 1743px 872px #fff, 1543px 59px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1382px 828px #fff, 1647px 565px #fff, 1579px 1995px #fff,
    1358px 829px #fff, 1994px 213px #fff, 1157px 607px #fff, 637px 1908px #fff,
    119px 1802px #fff, 645px 1554px #fff, 1px 1634px #fff, 1777px 640px #fff,
    512px 916px #fff, 975px 676px #fff, 231px 1671px #fff, 76px 1975px #fff,
    655px 1505px #fff, 640px 1472px #fff, 312px 363px #fff, 1504px 238px #fff,
    745px 1198px #fff, 679px 1866px #fff, 788px 1511px #fff, 1752px 1056px #fff,
    532px 459px #fff, 1344px 135px #fff, 1351px 1258px #fff, 1216px 1013px #fff,
    1405px 624px #fff, 1457px 1230px #fff, 1558px 1627px #fff, 496px 189px #fff,
    1573px 618px #fff, 810px 1565px #fff, 1298px 448px #fff, 12px 959px #fff,
    969px 1529px #fff, 51px 409px #fff, 1289px 389px #fff, 1363px 1417px #fff,
    1836px 683px #fff, 1628px 1141px #fff, 736px 31px #fff, 370px 1997px #fff,
    1971px 483px #fff, 1474px 849px #fff, 1746px 1274px #fff, 1699px 22px #fff,
    1660px 1581px #fff, 1565px 738px #fff, 1666px 500px #fff, 38px 1535px #fff,
    899px 509px #fff, 1063px 1306px #fff, 1775px 1553px #fff, 1994px 999px #fff,
    1419px 1693px #fff, 196px 856px #fff, 1216px 1602px #fff, 308px 929px #fff,
    50px 1437px #fff, 560px 888px #fff, 1590px 368px #fff, 1212px 349px #fff,
    1984px 1751px #fff, 1106px 1682px #fff, 811px 456px #fff, 1796px 1320px #fff,
    405px 1062px #fff, 1299px 1195px #fff, 731px 880px #fff, 52px 686px #fff,
    698px 189px #fff, 880px 935px #fff, 1624px 912px #fff, 1008px 1132px #fff,
    703px 92px #fff, 1542px 119px #fff, 1380px 1993px #fff, 832px 641px #fff,
    970px 406px #fff, 940px 1353px #fff, 21px 502px #fff, 1832px 642px #fff,
    1929px 1098px #fff, 1510px 1280px #fff, 1644px 1257px #fff,
    1539px 1299px #fff, 1710px 1630px #fff, 783px 236px #fff, 468px 1849px #fff,
    1744px 1356px #fff, 1379px 1500px #fff, 825px 43px #fff, 695px 1298px #fff,
    1612px 1745px #fff, 579px 1799px #fff, 1187px 717px #fff, 13px 1689px #fff,
    115px 1658px #fff, 1448px 832px #fff, 562px 1056px #fff, 1597px 1538px #fff,
    455px 1124px #fff, 1063px 385px #fff, 1879px 219px #fff, 1471px 1478px #fff,
    795px 1573px #fff, 110px 1694px #fff, 827px 498px #fff, 1347px 736px #fff,
    890px 938px #fff, 519px 846px #fff, 1365px 1337px #fff, 1880px 1669px #fff,
    361px 1148px #fff, 1987px 1191px #fff, 1958px 1106px #fff, 1999px 113px #fff,
    28px 1537px #fff, 669px 759px #fff, 1468px 446px #fff, 639px 307px #fff,
    1016px 1499px #fff, 1678px 1263px #fff, 796px 1516px #fff, 1703px 24px #fff,
    897px 630px #fff, 1587px 1592px #fff, 1702px 1955px #fff, 1025px 1281px #fff,
    647px 1092px #fff, 1670px 1167px #fff, 265px 1917px #fff, 1355px 1980px #fff,
    29px 397px #fff, 311px 946px #fff, 521px 196px #fff, 665px 367px #fff,
    148px 133px #fff, 1375px 1253px #fff, 723px 986px #fff, 1928px 962px #fff,
    1620px 357px #fff, 674px 261px #fff, 1172px 530px #fff, 1728px 1995px #fff,
    832px 398px #fff, 1037px 1923px #fff, 1258px 1178px #fff, 1913px 1507px #fff,
    470px 315px #fff, 506px 1138px #fff, 335px 220px #fff, 1530px 1599px #fff,
    1254px 977px #fff, 1428px 1254px #fff, 508px 623px #fff, 1147px 1958px #fff,
    716px 950px #fff, 1151px 1578px #fff, 255px 956px #fff, 627px 1570px #fff,
    1372px 259px #fff, 556px 656px #fff, 925px 1407px #fff, 222px 386px #fff,
    512px 431px #fff, 1670px 1929px #fff, 495px 742px #fff, 845px 586px #fff,
    1990px 1760px #fff, 345px 1199px #fff, 1902px 817px #fff, 532px 680px #fff,
    643px 504px #fff, 436px 1284px #fff, 167px 1984px #fff, 480px 1085px #fff,
    925px 1507px #fff, 610px 1966px #fff, 1615px 1097px #fff, 834px 1886px #fff,
    1355px 1393px #fff, 1538px 1226px #fff, 1287px 1336px #fff, 471px 545px #fff,
    68px 1950px #fff, 1927px 1988px #fff, 1226px 1006px #fff, 1540px 148px #fff,
    1215px 594px #fff, 212px 1598px #fff, 1627px 532px #fff, 132px 660px #fff,
    1795px 1063px #fff, 519px 554px #fff, 1798px 799px #fff, 1483px 118px #fff,
    885px 1016px #fff, 1864px 2px #fff, 1382px 842px #fff, 1578px 1033px #fff,
    1669px 1859px #fff, 1889px 1125px #fff, 1555px 417px #fff, 21px 1255px #fff,
    715px 1277px #fff, 882px 89px #fff, 699px 280px #fff, 1078px 905px #fff,
    1137px 385px #fff, 823px 250px #fff, 1536px 1215px #fff, 1479px 1941px #fff,
    402px 1615px #fff, 1155px 1632px #fff, 923px 1694px #fff, 549px 1196px #fff,
    438px 782px #fff, 271px 1912px #fff, 713px 1608px #fff, 987px 265px #fff,
    1946px 374px #fff, 1688px 804px #fff, 745px 898px #fff, 944px 1596px #fff,
    1883px 413px #fff, 1418px 864px #fff, 1427px 161px #fff, 1714px 1065px #fff,
    1791px 1877px #fff, 813px 1557px #fff, 1877px 1659px #fff, 575px 1242px #fff,
    1902px 1273px #fff, 62px 1307px #fff, 907px 493px #fff, 612px 1005px #fff,
    1584px 1571px #fff, 1541px 246px #fff, 817px 1979px #fff, 1879px 1160px #fff,
    1377px 1559px #fff, 872px 1060px #fff, 269px 1179px #fff, 241px 1172px #fff,
    932px 1736px #fff, 1749px 1237px #fff, 1500px 1389px #fff, 1543px 899px #fff,
    931px 1278px #fff, 1027px 1740px #fff, 1806px 1788px #fff, 1136px 27px #fff,
    1481px 650px #fff, 1133px 491px #fff, 904px 479px #fff, 150px 989px #fff,
    255px 747px #fff, 1815px 1956px #fff, 1016px 419px #fff, 1341px 1060px #fff,
    455px 1264px #fff, 1206px 830px #fff, 675px 1973px #fff, 990px 598px #fff,
    252px 460px #fff, 1723px 1812px #fff, 729px 1546px #fff, 575px 1649px #fff,
    818px 250px #fff, 1432px 54px #fff, 1441px 59px #fff, 1875px 1109px #fff,
    650px 754px #fff, 1562px 1139px #fff, 407px 1633px #fff, 1317px 1442px #fff,
    1430px 1320px #fff, 1980px 1482px #fff, 2000px 1618px #fff,
    383px 1739px #fff, 538px 448px #fff, 1508px 1142px #fff, 1781px 454px #fff,
    1776px 1461px #fff, 653px 1040px #fff, 1397px 630px #fff, 213px 1229px #fff,
    1150px 1389px #fff, 1937px 265px #fff, 887px 396px #fff, 978px 910px #fff,
    871px 1469px #fff, 318px 510px #fff, 409px 677px #fff, 1915px 1283px #fff,
    1432px 1022px #fff, 1437px 1823px #fff, 1562px 550px #fff,
    1127px 1056px #fff, 1496px 76px #fff, 1758px 73px #fff, 1916px 1189px #fff,
    679px 1809px #fff, 1224px 585px #fff, 22px 49px #fff, 277px 1568px #fff,
    396px 571px #fff, 830px 308px #fff, 1093px 1408px #fff, 59px 236px #fff,
    1707px 1010px #fff, 1703px 1835px #fff, 1442px 1636px #fff, 705px 247px #fff,
    97px 1239px #fff, 1235px 343px #fff, 139px 1291px #fff, 829px 80px #fff,
    1497px 258px #fff, 972px 1285px #fff, 1195px 1326px #fff, 1382px 319px #fff,
    1871px 766px #fff, 455px 575px #fff, 978px 1731px #fff, 1524px 1483px #fff,
    177px 1858px #fff, 493px 140px #fff, 718px 1482px #fff, 35px 436px #fff,
    666px 1919px #fff, 595px 796px #fff, 1231px 348px #fff, 1133px 1766px #fff,
    591px 1048px #fff, 1953px 1511px #fff, 645px 1364px #fff, 1289px 311px #fff,
    1408px 277px #fff, 1365px 1403px #fff, 11px 1754px #fff, 1871px 142px #fff,
    983px 809px #fff, 1528px 523px #fff, 1826px 1788px #fff, 742px 834px #fff,
    922px 150px #fff, 297px 507px #fff, 1091px 1862px #fff, 690px 1515px #fff,
    745px 748px #fff, 341px 895px #fff, 1793px 324px #fff, 1571px 400px #fff,
    1386px 243px #fff, 42px 1073px #fff, 538px 1901px #fff, 1317px 1625px #fff,
    94px 1360px #fff, 308px 526px #fff, 737px 532px #fff, 307px 979px #fff,
    965px 1765px #fff, 877px 1043px #fff, 1683px 114px #fff, 109px 573px #fff,
    1421px 600px #fff, 112px 345px #fff, 1278px 931px #fff, 205px 1706px #fff,
    1070px 440px #fff, 1411px 1379px #fff, 62px 623px #fff, 1665px 1555px #fff,
    1956px 578px #fff, 1298px 154px #fff, 1699px 983px #fff, 1453px 828px #fff,
    433px 1684px #fff, 980px 1435px #fff, 469px 189px #fff, 1082px 76px #fff,
    787px 1721px #fff, 255px 1608px #fff, 1598px 1854px #fff, 1196px 1908px #fff,
    350px 1881px #fff, 1387px 1283px #fff, 1415px 264px #fff, 68px 795px #fff,
    1613px 182px #fff, 898px 1221px #fff, 46px 216px #fff, 1633px 727px #fff,
    154px 822px #fff, 1812px 628px #fff, 1830px 1287px #fff, 1184px 457px #fff,
    152px 27px #fff, 327px 1494px #fff, 526px 1264px #fff, 730px 745px #fff,
    1930px 1529px #fff, 932px 1610px #fff, 491px 145px #fff, 163px 1180px #fff,
    130px 795px #fff, 703px 267px #fff, 614px 1417px #fff, 1546px 1569px #fff,
    687px 1062px #fff, 1392px 1259px #fff, 1358px 1138px #fff, 954px 1043px #fff,
    1233px 154px #fff, 553px 1153px #fff, 1315px 759px #fff, 518px 642px #fff,
    389px 1181px #fff, 1962px 1383px #fff, 1008px 1576px #fff,
    1615px 1141px #fff, 364px 1400px #fff, 126px 1530px #fff, 1007px 845px #fff,
    1463px 1868px #fff, 41px 38px #fff, 442px 269px #fff, 1063px 1194px #fff,
    1995px 519px #fff, 511px 290px #fff, 73px 1793px #fff, 1733px 1512px #fff,
    1214px 751px #fff, 1142px 710px #fff, 449px 192px #fff, 902px 1450px #fff,
    158px 389px #fff, 1000px 316px #fff, 1132px 839px #fff, 892px 1570px #fff,
    927px 1931px #fff, 1831px 298px #fff, 531px 632px #fff, 1089px 423px #fff,
    933px 970px #fff, 697px 498px #fff, 1929px 183px #fff, 891px 417px #fff,
    1073px 1206px #fff, 539px 1600px #fff, 603px 439px #fff, 736px 1756px #fff,
    197px 453px #fff, 1781px 23px #fff, 1189px 195px #fff, 1616px 279px #fff,
    535px 769px #fff, 513px 187px #fff, 1497px 711px #fff, 1843px 1611px #fff,
    769px 1342px #fff, 882px 1841px #fff, 1444px 1233px #fff, 309px 952px #fff,
    1526px 1414px #fff, 1355px 1309px #fff, 1480px 463px #fff, 258px 24px #fff,
    49px 33px #fff, 1818px 1842px #fff, 444px 248px #fff, 408px 796px #fff,
    798px 761px #fff, 1481px 1361px #fff, 1718px 41px #fff, 1620px 1759px #fff,
    1327px 1338px #fff, 290px 1669px #fff, 360px 1470px #fff, 167px 1284px #fff,
    512px 880px #fff, 1827px 423px #fff, 1468px 272px #fff, 1453px 327px #fff,
    982px 880px #fff, 1979px 791px #fff, 568px 939px #fff, 1717px 1627px #fff,
    1769px 1615px #fff, 15px 523px #fff, 1152px 1203px #fff, 285px 1666px #fff,
    856px 644px #fff, 25px 1749px #fff, 415px 1955px #fff, 275px 1781px #fff,
    1854px 514px #fff, 974px 317px #fff, 270px 1306px #fff, 705px 585px #fff,
    1776px 1292px #fff, 1797px 1652px #fff, 486px 1113px #fff, 62px 688px #fff,
    1503px 546px #fff, 1915px 1576px #fff, 719px 494px #fff, 1874px 1065px #fff,
    1309px 295px #fff, 1214px 457px #fff, 1845px 1470px #fff, 26px 1635px #fff,
    1864px 635px #fff, 201px 787px #fff, 747px 1754px #fff, 1701px 1636px #fff,
    1783px 1921px #fff, 1583px 1108px #fff, 89px 1361px #fff, 432px 1202px #fff,
    528px 81px #fff, 151px 659px #fff, 911px 318px #fff, 229px 1974px #fff,
    1222px 1339px #fff, 737px 1927px #fff, 1222px 669px #fff, 843px 1796px #fff,
    556px 518px #fff, 953px 896px #fff, 1071px 1567px #fff, 233px 2px #fff,
    1948px 1095px #fff, 626px 1384px #fff, 85px 1684px #fff, 393px 1965px #fff,
    1118px 225px #fff, 305px 1933px #fff, 1868px 1314px #fff, 491px 1576px #fff,
    1470px 417px #fff, 1507px 1214px #fff, 1462px 1629px #fff, 625px 1460px #fff,
    965px 1701px #fff, 904px 154px #fff, 137px 1430px #fff, 875px 1390px #fff,
    755px 2000px #fff, 678px 1830px #fff, 762px 468px #fff, 341px 422px #fff,
    451px 290px #fff, 1868px 1203px #fff, 1641px 1527px #fff, 1993px 1629px #fff,
    900px 252px #fff, 840px 1428px #fff, 96px 1717px #fff, 731px 57px #fff,
    1527px 1433px #fff, 1835px 674px #fff, 1296px 33px #fff, 701px 514px #fff,
    581px 318px #fff, 729px 1292px #fff, 182px 1256px #fff, 913px 360px #fff,
    948px 1018px #fff, 1799px 1791px #fff, 1121px 659px #fff, 1451px 377px #fff,
    187px 173px #fff, 485px 609px #fff, 1133px 661px #fff, 1757px 347px #fff,
    692px 1527px #fff, 1234px 1539px #fff, 1813px 1570px #fff,
    1784px 1279px #fff, 1491px 1565px #fff, 712px 1265px #fff, 594px 1514px #fff,
    795px 976px #fff, 1784px 1953px #fff, 103px 62px #fff, 1134px 765px #fff,
    1083px 794px #fff, 288px 1913px #fff, 1136px 1546px #fff, 73px 1755px #fff,
    972px 130px #fff, 16px 346px #fff, 1756px 1078px #fff, 1058px 1706px #fff,
    1675px 813px #fff, 227px 1710px #fff, 1502px 470px #fff, 163px 302px #fff,
    1406px 923px #fff, 332px 1565px #fff, 1414px 306px #fff, 401px 601px #fff,
    1992px 931px #fff, 1102px 559px #fff, 1605px 1px #fff, 595px 1468px #fff,
    1775px 249px #fff, 1546px 1873px #fff, 1899px 246px #fff, 172px 260px #fff,
    274px 1934px #fff, 645px 1139px #fff, 1213px 1715px #fff, 564px 1669px #fff,
    1795px 1057px #fff, 121px 1768px #fff, 164px 241px #fff, 1146px 1263px #fff,
    71px 1378px #fff, 388px 1518px #fff, 831px 840px #fff, 359px 70px #fff,
    1019px 188px #fff, 153px 1107px #fff, 1720px 1558px #fff, 471px 1354px #fff,
    877px 1473px #fff, 1949px 432px #fff, 882px 764px #fff, 1413px 299px #fff,
    1110px 266px #fff, 1802px 314px #fff, 1426px 325px #fff, 978px 483px #fff,
    438px 387px #fff, 1382px 1913px #fff, 958px 1388px #fff, 326px 737px #fff,
    960px 1660px #fff, 1103px 7px #fff, 894px 1291px #fff, 10px 933px #fff,
    389px 399px #fff, 1026px 1687px #fff, 6px 295px #fff, 1688px 265px #fff,
    1686px 341px #fff, 307px 1885px #fff, 1804px 320px #fff, 578px 1993px #fff,
    512px 1764px #fff, 586px 311px #fff, 1355px 251px #fff, 1306px 1053px #fff,
    583px 530px #fff, 934px 49px #fff, 273px 316px #fff, 747px 1722px #fff,
    616px 179px #fff, 771px 1291px #fff, 817px 521px #fff, 440px 1886px #fff,
    1811px 1908px #fff, 309px 1314px #fff, 1960px 1757px #fff,
    1776px 1301px #fff, 817px 1158px #fff, 1679px 1496px #fff,
    1997px 1331px #fff, 1514px 1048px #fff, 1020px 657px #fff, 1550px 290px #fff,
    228px 1565px #fff, 48px 1791px #fff, 1683px 1960px #fff, 1150px 794px #fff,
    1206px 981px #fff, 1784px 966px #fff, 873px 577px #fff, 1087px 1176px #fff,
    1781px 1806px #fff, 967px 324px #fff, 352px 1187px #fff, 1068px 1591px #fff,
    1967px 1808px #fff, 899px 988px #fff, 1270px 843px #fff, 1881px 1306px #fff,
    668px 581px #fff, 993px 1376px #fff, 1792px 942px #fff, 591px 1057px #fff,
    293px 489px #fff, 1945px 1743px #fff, 1743px 872px #fff, 1543px 59px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1957px 769px #fff, 194px 1523px #fff, 1058px 1672px #fff,
    69px 1143px #fff, 1598px 1022px #fff, 300px 13px #fff, 1911px 536px #fff,
    827px 1316px #fff, 1575px 1127px #fff, 1690px 1240px #fff, 898px 806px #fff,
    1120px 1072px #fff, 701px 1429px #fff, 1827px 245px #fff, 1779px 1730px #fff,
    476px 480px #fff, 1364px 691px #fff, 1417px 1909px #fff, 962px 838px #fff,
    1593px 679px #fff, 868px 932px #fff, 119px 265px #fff, 638px 302px #fff,
    1330px 140px #fff, 859px 993px #fff, 770px 6px #fff, 209px 1333px #fff,
    243px 681px #fff, 1199px 68px #fff, 1229px 1482px #fff, 430px 344px #fff,
    1058px 552px #fff, 366px 1763px #fff, 1424px 1039px #fff, 366px 721px #fff,
    55px 350px #fff, 1451px 1370px #fff, 372px 885px #fff, 425px 1945px #fff,
    373px 1088px #fff, 1965px 1241px #fff, 393px 825px #fff, 216px 1815px #fff,
    1878px 1958px #fff, 1338px 1503px #fff, 1783px 113px #fff, 1688px 767px #fff,
    894px 493px #fff, 588px 1871px #fff, 1506px 1998px #fff, 1600px 1322px #fff,
    696px 1811px #fff, 473px 1561px #fff, 1892px 273px #fff, 1109px 1865px #fff,
    1346px 114px #fff, 465px 462px #fff, 420px 1105px #fff, 555px 1836px #fff,
    803px 1570px #fff, 685px 651px #fff, 962px 1006px #fff, 1889px 1604px #fff,
    1843px 32px #fff, 967px 74px #fff, 180px 931px #fff, 1904px 487px #fff,
    501px 169px #fff, 433px 255px #fff, 1105px 1251px #fff, 1731px 197px #fff,
    1810px 754px #fff, 1372px 172px #fff, 1341px 1395px #fff, 1763px 153px #fff,
    1289px 1611px #fff, 1720px 572px #fff, 147px 927px #fff, 1645px 607px #fff,
    535px 1527px #fff, 114px 23px #fff, 485px 847px #fff, 1772px 269px #fff,
    888px 127px #fff, 1417px 69px #fff, 1480px 904px #fff, 691px 1392px #fff,
    1349px 453px #fff, 276px 73px #fff, 1842px 520px #fff, 1241px 1871px #fff,
    1257px 1006px #fff, 648px 157px #fff, 846px 138px #fff, 1814px 1840px #fff,
    810px 667px #fff, 1330px 122px #fff, 1299px 840px #fff, 1553px 566px #fff,
    335px 1873px #fff, 288px 1166px #fff, 362px 980px #fff, 536px 1887px #fff,
    1663px 1547px #fff, 1217px 863px #fff, 1274px 1836px #fff, 695px 1043px #fff,
    1633px 1657px #fff, 365px 361px #fff, 1424px 787px #fff, 638px 1170px #fff,
    981px 1554px #fff, 296px 1326px #fff, 863px 856px #fff, 654px 1857px #fff,
    666px 788px #fff, 309px 1043px #fff, 705px 1171px #fff, 1349px 1210px #fff,
    293px 1034px #fff, 857px 1731px #fff, 165px 1217px #fff, 1243px 1639px #fff,
    1431px 1541px #fff, 665px 631px #fff, 157px 938px #fff, 1714px 1630px #fff,
    1518px 111px #fff, 949px 409px #fff, 1639px 669px #fff, 788px 1175px #fff,
    462px 1032px #fff, 931px 648px #fff, 589px 947px #fff, 550px 795px #fff,
    1028px 1763px #fff, 1046px 1817px #fff, 1607px 829px #fff, 310px 1532px #fff,
    360px 67px #fff, 249px 61px #fff, 593px 761px #fff, 1567px 66px #fff,
    566px 1057px #fff, 361px 1382px #fff, 1448px 38px #fff, 531px 1140px #fff,
    670px 1153px #fff, 1483px 1209px #fff, 316px 316px #fff, 1141px 1660px #fff,
    1215px 662px #fff, 1272px 653px #fff, 218px 1985px #fff, 582px 950px #fff,
    203px 1344px #fff, 885px 370px #fff, 836px 401px #fff, 1058px 1760px #fff,
    68px 1346px #fff, 26px 1397px #fff, 252px 1199px #fff, 439px 561px #fff,
    774px 1330px #fff, 283px 1055px #fff, 1992px 576px #fff, 1788px 97px #fff,
    1587px 251px #fff, 892px 774px #fff, 1978px 430px #fff, 786px 716px #fff,
    533px 1988px #fff, 448px 293px #fff, 1237px 518px #fff, 1752px 821px #fff,
    285px 1251px #fff, 1066px 496px #fff, 372px 1344px #fff, 966px 367px #fff,
    200px 1676px #fff, 1100px 677px #fff, 1751px 1230px #fff, 1815px 839px #fff,
    1352px 1084px #fff, 580px 553px #fff, 1169px 1322px #fff, 1308px 1980px #fff,
    1366px 1608px #fff, 852px 670px #fff, 1630px 1402px #fff, 1675px 1949px #fff,
    1871px 1419px #fff, 809px 484px #fff, 233px 295px #fff, 964px 960px #fff,
    717px 847px #fff, 779px 1457px #fff, 1040px 191px #fff, 1996px 1363px #fff,
    892px 1517px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1957px 769px #fff, 194px 1523px #fff, 1058px 1672px #fff,
    69px 1143px #fff, 1598px 1022px #fff, 300px 13px #fff, 1911px 536px #fff,
    827px 1316px #fff, 1575px 1127px #fff, 1690px 1240px #fff, 898px 806px #fff,
    1120px 1072px #fff, 701px 1429px #fff, 1827px 245px #fff, 1779px 1730px #fff,
    476px 480px #fff, 1364px 691px #fff, 1417px 1909px #fff, 962px 838px #fff,
    1593px 679px #fff, 868px 932px #fff, 119px 265px #fff, 638px 302px #fff,
    1330px 140px #fff, 859px 993px #fff, 770px 6px #fff, 209px 1333px #fff,
    243px 681px #fff, 1199px 68px #fff, 1229px 1482px #fff, 430px 344px #fff,
    1058px 552px #fff, 366px 1763px #fff, 1424px 1039px #fff, 366px 721px #fff,
    55px 350px #fff, 1451px 1370px #fff, 372px 885px #fff, 425px 1945px #fff,
    373px 1088px #fff, 1965px 1241px #fff, 393px 825px #fff, 216px 1815px #fff,
    1878px 1958px #fff, 1338px 1503px #fff, 1783px 113px #fff, 1688px 767px #fff,
    894px 493px #fff, 588px 1871px #fff, 1506px 1998px #fff, 1600px 1322px #fff,
    696px 1811px #fff, 473px 1561px #fff, 1892px 273px #fff, 1109px 1865px #fff,
    1346px 114px #fff, 465px 462px #fff, 420px 1105px #fff, 555px 1836px #fff,
    803px 1570px #fff, 685px 651px #fff, 962px 1006px #fff, 1889px 1604px #fff,
    1843px 32px #fff, 967px 74px #fff, 180px 931px #fff, 1904px 487px #fff,
    501px 169px #fff, 433px 255px #fff, 1105px 1251px #fff, 1731px 197px #fff,
    1810px 754px #fff, 1372px 172px #fff, 1341px 1395px #fff, 1763px 153px #fff,
    1289px 1611px #fff, 1720px 572px #fff, 147px 927px #fff, 1645px 607px #fff,
    535px 1527px #fff, 114px 23px #fff, 485px 847px #fff, 1772px 269px #fff,
    888px 127px #fff, 1417px 69px #fff, 1480px 904px #fff, 691px 1392px #fff,
    1349px 453px #fff, 276px 73px #fff, 1842px 520px #fff, 1241px 1871px #fff,
    1257px 1006px #fff, 648px 157px #fff, 846px 138px #fff, 1814px 1840px #fff,
    810px 667px #fff, 1330px 122px #fff, 1299px 840px #fff, 1553px 566px #fff,
    335px 1873px #fff, 288px 1166px #fff, 362px 980px #fff, 536px 1887px #fff,
    1663px 1547px #fff, 1217px 863px #fff, 1274px 1836px #fff, 695px 1043px #fff,
    1633px 1657px #fff, 365px 361px #fff, 1424px 787px #fff, 638px 1170px #fff,
    981px 1554px #fff, 296px 1326px #fff, 863px 856px #fff, 654px 1857px #fff,
    666px 788px #fff, 309px 1043px #fff, 705px 1171px #fff, 1349px 1210px #fff,
    293px 1034px #fff, 857px 1731px #fff, 165px 1217px #fff, 1243px 1639px #fff,
    1431px 1541px #fff, 665px 631px #fff, 157px 938px #fff, 1714px 1630px #fff,
    1518px 111px #fff, 949px 409px #fff, 1639px 669px #fff, 788px 1175px #fff,
    462px 1032px #fff, 931px 648px #fff, 589px 947px #fff, 550px 795px #fff,
    1028px 1763px #fff, 1046px 1817px #fff, 1607px 829px #fff, 310px 1532px #fff,
    360px 67px #fff, 249px 61px #fff, 593px 761px #fff, 1567px 66px #fff,
    566px 1057px #fff, 361px 1382px #fff, 1448px 38px #fff, 531px 1140px #fff,
    670px 1153px #fff, 1483px 1209px #fff, 316px 316px #fff, 1141px 1660px #fff,
    1215px 662px #fff, 1272px 653px #fff, 218px 1985px #fff, 582px 950px #fff,
    203px 1344px #fff, 885px 370px #fff, 836px 401px #fff, 1058px 1760px #fff,
    68px 1346px #fff, 26px 1397px #fff, 252px 1199px #fff, 439px 561px #fff,
    774px 1330px #fff, 283px 1055px #fff, 1992px 576px #fff, 1788px 97px #fff,
    1587px 251px #fff, 892px 774px #fff, 1978px 430px #fff, 786px 716px #fff,
    533px 1988px #fff, 448px 293px #fff, 1237px 518px #fff, 1752px 821px #fff,
    285px 1251px #fff, 1066px 496px #fff, 372px 1344px #fff, 966px 367px #fff,
    200px 1676px #fff, 1100px 677px #fff, 1751px 1230px #fff, 1815px 839px #fff,
    1352px 1084px #fff, 580px 553px #fff, 1169px 1322px #fff, 1308px 1980px #fff,
    1366px 1608px #fff, 852px 670px #fff, 1630px 1402px #fff, 1675px 1949px #fff,
    1871px 1419px #fff, 809px 484px #fff, 233px 295px #fff, 964px 960px #fff,
    717px 847px #fff, 779px 1457px #fff, 1040px 191px #fff, 1996px 1363px #fff,
    892px 1517px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 996px 1796px #fff, 215px 1294px #fff, 1581px 1653px #fff,
    1148px 1511px #fff, 473px 898px #fff, 514px 1039px #fff, 516px 881px #fff,
    1547px 1777px #fff, 654px 373px #fff, 756px 1960px #fff, 1138px 369px #fff,
    342px 519px #fff, 1017px 821px #fff, 882px 693px #fff, 1045px 792px #fff,
    500px 306px #fff, 916px 352px #fff, 729px 1913px #fff, 301px 145px #fff,
    931px 1381px #fff, 1789px 1615px #fff, 776px 1468px #fff, 65px 75px #fff,
    584px 115px #fff, 1673px 578px #fff, 937px 1859px #fff, 513px 1771px #fff,
    1660px 1675px #fff, 657px 1909px #fff, 756px 1873px #fff, 1299px 879px #fff,
    1563px 465px #fff, 1023px 1936px #fff, 37px 472px #fff, 1615px 1173px #fff,
    306px 888px #fff, 1520px 1933px #fff, 621px 1155px #fff, 115px 243px #fff,
    672px 71px #fff, 1729px 422px #fff, 1380px 1651px #fff, 1899px 1595px #fff,
    1431px 318px #fff, 1503px 906px #fff, 913px 1118px #fff, 496px 467px #fff,
    1584px 98px #fff, 150px 470px #fff, 1319px 1304px #fff, 1613px 1303px #fff,
    845px 1010px #fff, 1880px 665px #fff, 1888px 1100px #fff, 857px 359px #fff,
    908px 200px #fff, 21px 1951px #fff, 739px 1200px #fff, 1117px 257px #fff,
    876px 447px #fff, 1486px 546px #fff, 778px 660px #fff, 209px 1641px #fff,
    719px 1186px #fff, 1227px 135px #fff, 1008px 256px #fff, 868px 359px #fff,
    819px 95px #fff, 139px 1885px #fff, 1407px 1492px #fff, 221px 693px #fff,
    117px 628px #fff, 112px 1833px #fff, 871px 363px #fff, 95px 1649px #fff,
    379px 1369px #fff, 1347px 640px #fff, 1776px 1859px #fff, 1858px 1178px #fff,
    223px 135px #fff, 147px 1092px #fff, 948px 1647px #fff, 632px 33px #fff,
    1716px 1499px #fff, 430px 68px #fff, 174px 119px #fff, 989px 1953px #fff,
    149px 1099px #fff, 574px 1965px #fff, 1976px 1960px #fff, 1564px 936px #fff,
    417px 536px #fff, 604px 253px #fff, 501px 1573px #fff, 95px 1700px #fff,
    1855px 694px #fff, 1211px 1946px #fff, 1292px 1941px #fff, 1957px 701px #fff,
    1057px 877px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 996px 1796px #fff, 215px 1294px #fff, 1581px 1653px #fff,
    1148px 1511px #fff, 473px 898px #fff, 514px 1039px #fff, 516px 881px #fff,
    1547px 1777px #fff, 654px 373px #fff, 756px 1960px #fff, 1138px 369px #fff,
    342px 519px #fff, 1017px 821px #fff, 882px 693px #fff, 1045px 792px #fff,
    500px 306px #fff, 916px 352px #fff, 729px 1913px #fff, 301px 145px #fff,
    931px 1381px #fff, 1789px 1615px #fff, 776px 1468px #fff, 65px 75px #fff,
    584px 115px #fff, 1673px 578px #fff, 937px 1859px #fff, 513px 1771px #fff,
    1660px 1675px #fff, 657px 1909px #fff, 756px 1873px #fff, 1299px 879px #fff,
    1563px 465px #fff, 1023px 1936px #fff, 37px 472px #fff, 1615px 1173px #fff,
    306px 888px #fff, 1520px 1933px #fff, 621px 1155px #fff, 115px 243px #fff,
    672px 71px #fff, 1729px 422px #fff, 1380px 1651px #fff, 1899px 1595px #fff,
    1431px 318px #fff, 1503px 906px #fff, 913px 1118px #fff, 496px 467px #fff,
    1584px 98px #fff, 150px 470px #fff, 1319px 1304px #fff, 1613px 1303px #fff,
    845px 1010px #fff, 1880px 665px #fff, 1888px 1100px #fff, 857px 359px #fff,
    908px 200px #fff, 21px 1951px #fff, 739px 1200px #fff, 1117px 257px #fff,
    876px 447px #fff, 1486px 546px #fff, 778px 660px #fff, 209px 1641px #fff,
    719px 1186px #fff, 1227px 135px #fff, 1008px 256px #fff, 868px 359px #fff,
    819px 95px #fff, 139px 1885px #fff, 1407px 1492px #fff, 221px 693px #fff,
    117px 628px #fff, 112px 1833px #fff, 871px 363px #fff, 95px 1649px #fff,
    379px 1369px #fff, 1347px 640px #fff, 1776px 1859px #fff, 1858px 1178px #fff,
    223px 135px #fff, 147px 1092px #fff, 948px 1647px #fff, 632px 33px #fff,
    1716px 1499px #fff, 430px 68px #fff, 174px 119px #fff, 989px 1953px #fff,
    149px 1099px #fff, 574px 1965px #fff, 1976px 1960px #fff, 1564px 936px #fff,
    417px 536px #fff, 604px 253px #fff, 501px 1573px #fff, 95px 1700px #fff,
    1855px 694px #fff, 1211px 1946px #fff, 1292px 1941px #fff, 1957px 701px #fff,
    1057px 877px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
